<template>


  <SkBasicForm 
    v-if="is_loading"
    />

  <div v-else-if="data_profile?.items_room || data_profile?.items_user" class="modal_user_content">

    <div class="content_profile" >

      <div class="settings_description">

        <div class="followingList">
          <template v-for="pro in data_profile.items_user">
            <GrowerSettingsBannedItem
              :data="pro"
              :data_profile="data_profile"
              :user-id="props.userId"
              type="user"
              />
          </template>
          <template v-for="pro in data_profile.items_room">
            <GrowerSettingsBannedItem
              :data="pro"
              :data_profile="data_profile"
              :user-id="props.userId"
              type="room"
              />
          </template>
        </div>
  
      </div>

    </div>

  </div>


</template>

<script setup>
 

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const {$api, $tagsUtil, $popup, $ga} = useNuxtApp()


const props = defineProps({
  userId: {
    type: Number
  }
})

const loadData = async function(grower_id) {   
  const response = await $api.getGrowerSettings(grower_id, 'banned')  
  return response;  
}


const { pending: is_loading,  data: data_profile } = await useLazyAsyncData('data_profile', async () => await loadData(props.userId))





</script>

<style scoped>

.content_profile {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
}

.modal_user_content .settings_description {
    display: inline-block;
    vertical-align: middle;
    margin-right: auto;
    width: 100%;
}

.modal_user_content .settings_description .row{
    display: flex;
    width: 100%;
    margin-top: 15px;
}

.followingList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}


.followingList.centered {
}

</style>
