<template>

  <div class="banned_item" v-if="props.data && is_show">
    
    <div v-if="type == 'user'" class="event">
      <div class="label">
        <NuxtLink :class="'user_link ' + (props.data.is_premium ? 'is_premium ' : '') + (props.data.is_premier ? 'is_premier' : '')" :to="props.data.link">
           <img :alt="props.data.name" class="user_avatar" :src="props.data.avatar_small">
           <div :class="'user_range u_range_' + props.data.status"></div>
        </NuxtLink>
      </div>
      <div class="content">
        <div class="summary">
          <NuxtLink :to="props.data.link">
            {{ props.data.name }}
          </NuxtLink>
          <div class="date">
            {{ $constants.status[props.data.status] ? $t($constants.status[props.data.status]) : '' }}
          </div>

          <div class="btns_follow">
            <div class="btn unfollow_btn" @click="unBanUser(props.data.id)">
              {{ $t('profile_menu_edit_profile_button_unblock') }} 
            </div>
          </div>

        </div>


      </div>

    </div>

    <div v-if="type == 'room'" class="event">
      <div v-if="props.data.status" class="label">
        <NuxtLink :class="'user_link ' + (props.data.is_premium ? 'is_premium ' : '') + (props.data.is_premier ? 'is_premier' : '')" :to="props.data.link">
           <img :alt="props.data.name" class="user_avatar" :src="props.data.avatar_small">
           <div :class="'user_range u_range_' + props.data.status"></div>
        </NuxtLink>
      </div>
      <div v-else class="label">
        <div class="user_link">
          <img :alt="props.data.name" class="user_avatar" :src="props.data.avatar_small">
        </div>
      </div>
      <div class="content">
        <div class="summary">
          <NuxtLink v-if="props.data.status" :to="props.data.link">
            {{ props.data.name }}
          </NuxtLink>
          <span v-else class="">
            {{ props.data.name }}
           </span>
          <div class="date">
            {{ $t('profile_menu_edit_profile_chat_group') }} 
          </div>

          <div class="btns_follow">
            <div class="btn warning" @click="unBanRoom(props.data.id)">
              {{ $t('profile_menu_edit_profile_button_unblock') }} 
            </div>
          </div>

        </div>


      </div>

    </div>

  </div>

</template>

<script setup>

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const {$api, $tagsUtil, $popup, $ga} = useNuxtApp()


// var grower_id = ref(route.params['id']);
var is_show = ref(true);

var props = defineProps({
  data: {
    type: Object,
    required: true
  },
  data_profile: {
    type: Object,
    required: true
  },
  type: {
    type: String,
    required: true
  },
  userId: {
    type: Number
  }
})
 
const unBanUser = function(id){
   $api.deleteProfileBanned(props.userId, 'user', id)
   .then((res) => {
      is_show.value = false;
   })
   .catch(function (error) {

   });
}

const unBanRoom = function(id){
   $api.deleteProfileBanned(props.userId, 'room', id)
   .then((res) => {
      is_show.value = false;
   })
   .catch(function (error) {

   });
}

 
</script>

<style scoped>
 
  
/* FOLLOW */

.banned_item{
  display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
        width: 100%;
}

.banned_item .content{
    margin: 0.5em 0em 0.35em 1.14em;
    display: block;
    width: 100%;
}

.banned_item .content .summary{
    margin: 0em;
    font-size: 1em;
    font-weight: bold;
    color: var(--un-text-color-gray);
}

.banned_item .content .summary .date{
    display: inline-block;
    float: none;
    font-weight: normal;
    font-size: 0.85714286em;
    font-style: normal;
    margin: 0em 0em 0em 0.5em;
    padding: 0em;
    color: var(--un-text-color-gray);
}

.banned_item .btns_follow,
.banned_item .btns_follow{
    display: inline-block;
    float: right;
}

.banned_item .event .user_link,
.banned_item .event .user_link{
    position: relative;
    color: var(--un-text-color);
    text-decoration: none;
    height: 60px;
}
.banned_item .event .user_range,
.banned_item .event .user_range{
    position: absolute;
    bottom: -10px;
    right: -10px;
}

.banned_item .event .report_avatar{
    border-radius: 3px!important;
}

.banned_item .event{
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 45px;
    padding: 0.21rem 0em;
    margin: 0em;
    background: none;
    border-top: none;
}

.banned_item .event .label {
    display: block;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 2.5em;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    text-align: left;
    height: 60px;
}

.banned_item .event .user_avatar{
    width: 40px;
    border-radius: 30px;
    vertical-align: middle;
    margin-right: 5px;
}
.banned_item .event .user_name{
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 56px);
}

.banned_item .event .follow_btn{
    box-shadow: 0px 0px 0px 1px #198f35 inset !important;
    color: #198f35 !important;
    background: transparent none !important;
    font-weight: normal;
    height: 1em;
}

.banned_item .event .unfollow_btn{
    box-shadow: 0px 0px 0px 1px #db2828 inset !important;
    color: #DB2828 !important;
    background: transparent none !important;
    font-weight: normal;
    height: 1em;
}

@media (max-width: 768px) {

    .banned_item{
    padding-left: 1rem;
    padding-right: 1rem;
    }
    .banned_item .btns_follow,
    .banned_item .btns_follow{
        position: absolute;
        right: 0px;
        top: 0px;
    }
    .banned_item > .event > .content a{
        max-width: calc(100% - 111px);
        white-space: nowrap;
        overflow: hidden;
        /* background: #fc0; */
        /* padding: 5px; */
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: middle;
    }
}
  



</style>
